import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { Button, TextField, FormControlLabel, Switch } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialog';
import { updateCloverPoint } from '../services/cloverServices';
import CloverPointForm from './schemas/CloverPointForm';
import CloverPointFormProps from '../types/forms/CloverPointFormProps';
import EditCloverPointConfigContext from 'contexts/EditCloverPointConfigContext';

export default ({ cloverPoint }: CloverPointFormProps): JSX.Element => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const { selectedCloverPointConfig } = React.useContext(EditCloverPointConfigContext);
  const { t } = useTranslation();

  const onConfirm = async (): Promise<void> => {
    try {
      if (!selectedCloverPointConfig) return;
      const data = await updateCloverPoint(selectedCloverPointConfig.id, { ...formik.values });

      if (data) {
        history.push('/private/admin/payments/clover-point/posnets');
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      id: cloverPoint?.id ?? '',
      description: cloverPoint?.description ?? '',
      model: cloverPoint?.model ?? '',
      name: cloverPoint?.name,
      isPaired: cloverPoint?.isPaired ?? false,
      deviceAddress: cloverPoint?.deviceAddress ?? '',
    },
    validationSchema: CloverPointForm(t),
    onSubmit: async () => {
      setOpenDialog(true);
    },
  });

  return (
    <div>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="name"
            name="name"
            type="string"
            label={t('cloverPointConfig-form-name')}
            value={formik.values.name}
            onChange={formik.handleChange}
            disabled={true}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="model"
            name="model"
            type="string"
            label={t('cloverPointConfig-form-model')}
            value={formik.values.model}
            onChange={formik.handleChange}
            disabled={true}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="description"
            name="description"
            type="string"
            label={t('cloverPointConfig-form-description')}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="deviceAddress"
            name="deviceAddress"
            type="string"
            label={t('cloverPoint-form-device-address')}
            value={formik.values.deviceAddress}
            onChange={formik.handleChange}
            error={formik.touched.deviceAddress && Boolean(formik.errors.deviceAddress)}
            helperText={formik.touched.deviceAddress && formik.errors.deviceAddress}
          />
        </div>
        <div className="vertical-bottom-space">
          <FormControlLabel
            id="isPaired"
            name="isPaired"
            control={<Switch checked={formik.values.isPaired} onChange={formik.handleChange} />}
            label={t('cloverPoint-form-device-is-paired')}
            disabled={formik.isSubmitting}
          />
        </div>
        <div className="vertical-bottom-space">
          <Button color="primary" variant="contained" fullWidth type="submit">
            {`${t('cloverPointConfig-form-edit')} `}
          </Button>
        </div>
      </form>
    </div>
  );
};
