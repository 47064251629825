import { TFunction } from 'react-i18next';
import * as yup from 'yup';

export default (t: TFunction, isForSubsidiaryCreation: boolean): any =>
  yup.object({
    name: yup.string().required(t('schema-FiservQrSubsidiary-form-name-required')),
    address: yup.string().required(t('schema-FiservQrSubsidiary-form-address-required')),
    phone: yup
      .string()
      .required(t('schema-FiservQrSubsidiary-form-phone-required'))
      .matches(
        /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/,
        t('schema-FiservQrSubsidiary-form-phone-matches'),
      ),
    streetNumber: yup.string().required(t('schema-FiservQrSubsidiary-form-streetNumber-required')),
    streetName: yup.string().required(t('schema-FiservQrSubsidiary-form-streetName-required')),
    city: yup.string().required(t('schema-FiservQrSubsidiary-form-city-required')),
    postalCode: yup.string().required(t('schema-FiservQrSubsidiary-form-postalCode-required')),
    department: yup.string().required(t('schema-FiservQrSubsidiary-form-department-required')),
    country: yup.string().required(t('schema-FiservQrSubsidiary-form-country-required')),
    state: yup.string().required(t('schema-FiservQrSubsidiary-form-state-required')),
    token: isForSubsidiaryCreation
      ? yup.string().required(t('schema-FiservQrSubsidiary-form-token-required'))
      : yup.string(),
  });
