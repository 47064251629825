import axiosInstance from './axios';
import PaginationData from 'types/models/PaginationData';
import CloverPointConfig from 'types/models/CloverPointConfig';
import CloverPoint from 'types/models/CloverPoint';

export const getCloverPointConfigs = async (page?: number): Promise<PaginationData<CloverPointConfig> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `payments/clover-point/configs?page=${page ?? 1}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseCloverPointConfigs(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeCloverPointConfig = async (
  cloverPointConfig: CloverPointConfig,
): Promise<CloverPointConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: 'payments/clover-point/config',
      data: cloverPointConfig,
    });
    return parseCloverPointConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateCloverPointConfig = async (
  cloverPointConfigId: string,
  cloverPointConfig: CloverPointConfig,
): Promise<CloverPointConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'put',
      url: `payments/clover-point/config/${cloverPointConfigId}`,
      data: cloverPointConfig,
    });
    return parseCloverPointConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getCloverPoints = async (
  cloverPointConfigId: string,
  page?: number,
): Promise<PaginationData<CloverPoint> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `payments/clover-point/config/${cloverPointConfigId}/clover-points?page=${page ?? 1}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseCloverPoints(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateCloverPoint = async (
  cloverPointConfigId: string,
  cloverPoint: CloverPoint,
): Promise<CloverPoint | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'put',
      url: `payments/clover-point/config/${cloverPointConfigId}/clover-points/${cloverPoint.id}`,
      data: cloverPoint,
    });
    return parseCloverPoint(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateCloverPoints = async (
  cloverPointConfigId: string,
  merchantId: string,
  cloverAccessToken: string,
  cloverServerUrl: string,
): Promise<CloverPointConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'put',
      url: `payments/clover-point/config/${cloverPointConfigId}/update-clover-points`,
      data: {
        merchantId,
        cloverAccessToken,
        cloverServerUrl,
      },
    });
    return parseCloverPointConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const parseCloverPointConfigs = (data: any): CloverPointConfig[] => {
  return data.map((item: any): CloverPointConfig => parseCloverPointConfig(item));
};

const parseCloverPointConfig = (data: any): CloverPointConfig => {
  return {
    id: data.id,
    description: data.description,
    applicationId: data.application_id,
    accessToken: data.access_token,
    cloverServer: data.clover_server,
    merchantId: data.merchant_id,
    friendlyId: data.friendly_id,
    usesSitefApp: data.uses_sitef_app === 1,
    merchantTaxId: data.merchant_tax_id ?? undefined,
    isvTaxId: data.isv_tax_id ?? undefined,
  };
};

export const parseCloverPoints = (data: any): CloverPoint[] => {
  return data.map((item: any): CloverPoint => parseCloverPoint(item));
};

export const parseCloverPoint = (data: any): CloverPoint => {
  return {
    id: data.id,
    description: data.description,
    model: data.model,
    isPaired: data.is_paired === 1,
    name: data.name ?? '',
    deviceAddress: data.device_address ?? '',
    serial: data.serial ?? '',
  };
};
